import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';

const Landing = styled.div`
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

`

function LandingPage(props){
    return(

        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            >

        {props=>(
            <Landing>
                <h1 className="hvr-buzz-out">thoughtscape.io</h1>
                <p>cloud | web | ideas</p>
                <br/>
               
                
            </Landing>

        )}
       
        </Spring>
    )
    
}

export default LandingPage;
